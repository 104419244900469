import '../App.css';
import FormListItem from './form-list-item';
import { createNewRecipe, updateRecipe } from '../services/api-services';

export default function RecipeForm (props) {

    const handleFieldChange = (e) => {
        props.setState({ ...props.state, formData: { ...props.state.formData, [e.currentTarget.id]: e.currentTarget.value }});
    }

    const handleListItemAddition = (e) => {
        let newData = [];
        switch (e.currentTarget.value) {
            case 'ingredients': 
                newData = {
                    amount: '',
                    measurement: '',
                    name: '',
                    calories: ''
                };
                break;
            case 'steps': 
                newData = {
                    stepNumber: props.state.formData.steps.length + 1,
                    heading: '',
                    description: ''
                }
                break;
            default:
        }
        let state = { ...props.state, formData: { ...props.state.formData, [e.currentTarget.value]: [...props.state.formData[e.currentTarget.value], newData] }};
        delete state.recipes;
        props.setState(state);
    }

    const handleListItemChange = (e) => {
        let arr = props.state.formData[e.dataType];
        arr.splice(e.index, 1, e.formListItemData);
        props.setState({ ...props.state, formData: { ...props.state.formData, [e.dataType]: arr }});
    }

    const handleConfirm = async (e) => {
        let response;
        if (props.state.formView === 'create') {
            response = await createNewRecipe(props.state.formData);
        } else if (props.state.formView === 'edit') {
            response = await updateRecipe(props.state.formData);
        }
        if (response.code === 204) {
            props.setMessage('new recipe entry created', 'success', 2500, { detail: response.data, view: 'detail', recipes: undefined });
            return;
        }
        props.setMessage(response.message, 'error');
    }

    return (
        <div className='container column full-width padding-s full-height'>
            <h2 className='full-width font-size-xl bold text-center margin-h-xs margin-v-xs'>{props.state.formView} recipe</h2>
            <input className='padding-xs margin-xs width-xl' id='name' placeholder='name' value={props.state.formData.name} onChange={handleFieldChange} />
            <input className='padding-xs margin-xs width-xl' id='servings' placeholder='servings' type='number' value={props.state.formData.servings} onChange={handleFieldChange} />
            <input className='padding-xs margin-xs width-xl' id='caloriesPerServing' placeholder='calories per serving' type='number' value={props.state.formData.caloriesPerServing} onChange={handleFieldChange} />
            <input className='padding-xs margin-xs width-xl' id='category' placeholder='category' value={props.state.formData.category} onChange={handleFieldChange} />
            <input className='padding-xs margin-xs width-xl' id='cuisine' placeholder='cuisine' value={props.state.formData.cuisine} onChange={handleFieldChange} />
            <input className='padding-xs margin-xs width-xl' id='tags' placeholder='tags' value={props.state.formData.tags} onChange={handleFieldChange} />
            <h4 className='margin-v-m bold font-size-l text-left margin-h-xs'>ingredients</h4>
            {props.state.formData.ingredients.map((ingredient, index) => (
                <FormListItem 
                    state={props.state}
                    setState={props.setState}
                    data={ingredient}
                    index={index}
                    handleListItemChange={handleListItemChange}
                    dataType='ingredients'
                    key={'ingredient-' + index}
                />
            ))}
            <button className='text-left background-none border-none border-radius-s fill-on-hover bold font-size-s full-width' onClick={handleListItemAddition} value='ingredients' >add ingredient</button>
            <h4 className='margin-v-m bold font-size-l text-left margin-h-xs'>steps</h4>
            {props.state.formData.steps.map((step, index) => (
                <FormListItem 
                    state={props.state}
                    setState={props.setState}
                    data={step}
                    index={index}
                    handleListItemChange={handleListItemChange}
                    dataType='steps'
                    key={'step-' + index}
                />
            ))}
            <button className='text-left background-none border-none border-radius-s fill-on-hover bold font-size-s full-width' onClick={handleListItemAddition} value='steps'>add step</button>
            <button className='margin-v-m text-right background-none border-none border-radius-s fill-on-hover-confirm bold font-size-xl width-xl' onClick={handleConfirm} value={props.state.formView}>confirm</button>
        </div>
    );
}