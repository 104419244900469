import axios from 'axios';
export { getAllPrepItems, createOnePrepItem, getOnePrepItemById, updateOnePrepItemById, deleteOnePrepItemById } from './prep-item-services';
export { getAllShoppingItems, createOneShoppingItem, getOneShoppingItemById, updateOneShoppingItemById, deleteOneShoppingItemById } from './shopping-item-services';

const getUrl = async () => {
    // return 'http://localhost:5500/api';
    return process.env.REACT_APP_SERVER_URL;
}

export const getAllRecipes = async () => {
    let response = await axios.get(await getUrl() + '/recipes');
    if (response.data.code === 200) {
        return response.data.data;
    }
    return [];
}

export const createNewRecipe = async (formData) => {
    let response = await axios.post(await getUrl() + '/recipes', formData);
    if (response.data.success) {
        return { code: 204, data: response.data.data};
    }
    return { code: 501, message: response.data.message};
}

export const getDetailView = async (id) => {
    let response = await axios.get(await getUrl() + '/recipes/' + id);
    if (response.data.code === 200) {
        return { code: 200, data: response.data.data};
    }
    return { code: 501, message: 'could not retrieve recipe details' };
}

export const updateRecipe = async (formData) => {
    let id = formData.id;
    delete formData.id;
    let caloriesPerServing = formData.caloriesPerServing;
    delete formData.caloriesPerServing;
    formData.calories_per_serving = caloriesPerServing;
    ['ingredients', 'steps'].forEach(key => {
        formData[key] = JSON.stringify(formData[key]);
    });
    let response = await axios.put(await getUrl() + '/recipes/' + id, formData);
    if (response.data.success) {
        return { code: response.data.code, data: response.data.data };
    }
    return { code: response.data.code, message: response.data.message };
}

export const deleteRecipe = async (id) => {
    let response = await axios.delete(await getUrl() + '/recipes/' + id);
    return response.data;
}

export const attemptLogin = async (password) => {
    let response = await axios.post(await getUrl() + '/login', { password: password });
    if (response.data.success) {
        return true;
    }
    return false;
}