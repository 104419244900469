import { getDetailView } from '../services/api-services';
import { Fragment } from 'react';
import '../App.css';

export default function ListItem (props) {

    const handleListItemClick = async (e) => {
        if (props.dataType === 'recipe') {
            let response = null;
            if (!props.data.ingredients.length) {
                response = await getDetailView(props.data.id);
            }
            if (response === null) {
                props.setState({ ...props.state, detail: props.data, view: 'detail' });
                return;
            }
            if (response.code === 200) {
                let recipes = props.state.recipes;
                recipes.splice(props.index, 1, response.data);
                let state = { ...props.state, detail: response.data, view: 'detail', recipes: recipes };
                props.setState(state);
                return;
            }
            props.setMessage(response.message, 'error');
        }
    }

    return (
        <div className={`container row padding-m width-xxl margin-h-xs ${(props.dataType === 'step') ? 'align-top' : 'align-bottom'} ${(props.dataType === 'recipe') ? 'box-shadow cursor-pointer fill-on-hover space-between' : ''}`} onClick={handleListItemClick}>
            {(props.dataType === 'recipe') && 
                <Fragment>
                    <h3 className='font-size-xl'>{props.data.name}</h3>
                    <h3 className='font-size-m italics grayed'>{props.data.caloriesPerServing}</h3>
                </Fragment>
            }
            {(props.dataType === 'ingredient') &&
                <Fragment>
                    <p className='font-size-m margin-h-xs'>{props.data?.amount}</p>
                    <p className='font-size-m'>{props.data?.measurement}</p>
                    <p className='margin-h-s font-size-l bold'>{props.data?.name}</p>
                    <p className='font-size-s italics grayed'>({props.data?.calories})</p>
                </Fragment>
            }
            {(props.dataType === 'step') &&
                <Fragment>
                    <p className='bold font-size-xl'>{props.data?.stepNumber}</p>
                    <div className='margin-h-s'>
                        <p>{props.data?.description}</p>
                    </div>
                </Fragment>
            }
        </div>
    );
}