import '../App.css';
import { useState } from 'react';
import { attemptLogin } from '../services/api-services';

export default function Login (props) {

    const [password, setPassword] = useState('');

    const handlePasswordChange = (e) => {
        setPassword(e.currentTarget.value);
    }

    const handleKeyDown = async (e) => {
        if (!(e.code === 'Enter')) return;
        let response = await attemptLogin(password);
        props.setState({ ...props.state, isLoggedIn: response });
        if (!response) {
            props.setMessage('login attempt failed', 'error', 1000);
        }
    }

    return (
        <div className='container column align-center justify-center'>
            <input type='password' value={password} onChange={handlePasswordChange} onKeyDown={handleKeyDown}></input>
        </div>
    );
}