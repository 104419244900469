import '../App.css';

export default function SearchBar (props) {

    const handleSearchTermChange = (e) => {
        props.setState({ ...props.state, searchTerm: e.currentTarget.value });
        props.filterRecipesList(e.currentTarget.value);
    }

    return (
        <div className='margin-h-s width-full'>
            <input className='padding-s width-xl border-radius-xs' 
                type='text' 
                placeholder='search' 
                value={props.state.searchTerm}
                onChange={handleSearchTermChange}
            />
        </div>
    );
}