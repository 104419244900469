import '../App.css';
import Header from './header';
import PrepList from './prep-list';
import RecipeForm from './recipe-form';
import RecipesList from './recipes-list';
import ShoppingList from './shopping-list';
import Detail from './detail';

export default function Main (props) {

    return (
        <div className='container column justify-left align-center width-full overflow-hidden'>
            <Header state={props.state} setState={props.setState} />
            <div className='margin-top-header width-full border relative height-full'>
                {!['recipe form', 'detail'].includes(props.state.view) &&
                    <h3 className='font-size-xl bold margin-h-xl width-xl'>{props.state.view}</h3>
                }
                {props.state.view === 'recipes' &&
                    <RecipesList state={props.state} setState={props.setState} setMessage={props.setMessage} />
                }
                {props.state.view === 'detail' &&
                    <Detail state={props.state} setState={props.setState} setMessage={props.setMessage} />
                }
                {props.state.view === 'prep list' &&
                    <PrepList state={props.state} setState={props.setState} setMessage={props.setMessage}/>
                }
                {props.state.view === 'shopping list' &&
                    <ShoppingList state={props.state} setState={props.setState} setMessage={props.setMessage}/>
                }
                {props.state.view === 'recipe form' &&
                    <RecipeForm state={props.state} setState={props.setState} setMessage={props.setMessage}/>
                }
            </div>
        </div>
    );
}