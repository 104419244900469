import { useEffect, useState } from 'react';
import { getAllRecipes } from '../services/api-services';
import '../App.css';
import RecipeListHeader from './recipes-list-header';
import ListItem from './list-item';

export default function RecipesList (props) {

    const [recipes, setRecipes] = useState(props.state?.recipes || []);
    const [filteredList, setFilteredList] = useState(recipes || []);

    const fetchAllRecipes = async () => {
        let recipes = await getAllRecipes();
        props.setState({ ...props.state, recipes: recipes });
        setRecipes(recipes);
    }

    const filterRecipesList = (searchTerm) => {
        setFilteredList(recipes.filter(recipeRecord => {
            return (recipeRecord.name.includes(searchTerm) || recipeRecord.tags.includes(searchTerm));
        }));
    }

    useEffect(() => {
        if (props.state.recipes === undefined) {
            fetchAllRecipes();
        }
    }, [props.state]);

    return (
        <div className='container column width-xl margin-h-s'>
            <RecipeListHeader 
                state={props.state} 
                setState={props.setState} 
                filterRecipesList={filterRecipesList}
            />
            {filteredList.length > 0 && 
                filteredList.map((recipe, index) => {
                    return <ListItem 
                        key={recipe.id} 
                        dataType='recipe' 
                        state={props.state} 
                        setState={props.setState} 
                        data={recipe}
                        index={index}
                        setMessage={props.setMessage}
                    />
                })
            }
            {(recipes.length > 0 && !filteredList.length) &&
                <div>
                    no recipes matching search term found
                </div>
            }
            {(!recipes.length) &&
                <div>
                    no recipes found
                </div>
            }
        </div>
    );
}