import '../App.css';
import ListItem from './list-item';
import { deleteRecipe } from '../services/api-services';

export default function Detail (props) {

    const onEditButtonClick = () => {
        props.setState({ ...props.state, view: 'recipe form', formView: 'edit', formData: props.state.detail });
    }

    const onDeleteButtonClick = async () => {
        let response = await deleteRecipe(props.state?.detail?.id);
        if (response.code === 202) {
            let state = { ...props.state, view: 'recipes', detail: null };
            delete state.recipes;
            props.setState(state);
            return;
        }
        props.setMessage(response.message, 'error');
    }

    return (
        <div className='container column padding-m width-xxl justify-left'>
            <div className='container row justify-right full-width'>
                <button className='text-center background-none border-none border-radius-s fill-on-hover bold font-size-l width-xs edit-button' onClick={onEditButtonClick}>edit</button>
                <button className='text-center background-none border-none border-radius-s fill-on-hover bold font-size-l width-xs delete-button' onClick={onDeleteButtonClick}>delete</button>
            </div>
            <h2 className='font-size-xl bold text-left full-width'>{props.state?.detail?.name}</h2>
            <div className='container row margin-v-s text-left full-width'>
                <p className='font-size-m'><span className='italics font-size-xs'>servings:</span> <span className='bold'>{props.state?.detail?.servings}</span></p>
                <p className='font-size-m margin-h-m'><span className='italics font-size-xs'>calories per serving:</span> <span className='bold'>{props.state?.detail?.caloriesPerServing}</span></p>
                <p className='font-size-m'><span className='italics font-size-xs'>category:</span> <span className='bold'>{props.state?.detail?.category}</span></p>
                <p className='font-size-m margin-h-m'><span className='italics font-size-xs'>cuisine:</span> <span className='bold'>{props.state?.detail?.cuisine}</span></p>
            </div>
            <div className='container column box-shadow border-radius width-xl padding-s margin-v-s'>
                {props.state?.detail?.ingredients.map(ingredient => (
                    <ListItem 
                        key={ingredient.name}
                        state={props.state}
                        setState={props.setState}
                        setMessage={props.setMessage}
                        dataType='ingredient'
                        data={ingredient}
                    />
                ))}
            </div>
            <div className='container column width-full text-left padding-s margin-v-s'>
                {props.state?.detail?.steps.map(step => (
                    <ListItem 
                        key={`step-${step.stepNumber}`}
                        state={props.state}
                        setState={props.setState}
                        setMessage={props.setMessage}
                        dataType='step'
                        data={step}
                    />
                ))}
            </div>
            <p className='text-left italics font-size-s grayed'>{props.state?.detail?.tags}</p>
        </div>
    );
}