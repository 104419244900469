import '../App.css';
import SearchBar from './search-bar';

export default function RecipeListHeader (props) {

    const onButtonClick = (e) => {
        props.setState({ ...props.state, view: e.target.value, formView: 'create', formData: {
            name: '',
            caloriesPerServing: '',
            servings: '',
            tags: [],
            ingredients: [{
              amount: '',
              measurement: '',
              name: '',
              calories: ''
            }],
            steps: [
              {
                stepNumber: 1,
                heading: '',
                description: ''
              }
            ],
            cuisine: '',
            category: ''
          } 
        });
    }

    return (
        <div className='container column box-shadow padding-s justify-center align-left width-xl margin-v-s'>
            <div className='container row align-center width-full'>
                <button className='background-none button-border border-radius-s fill-on-hover bold font-size-xl cursor-pointer' onClick={onButtonClick} value='recipe form'>+</button>
                <SearchBar 
                  state={props.state} 
                  setState={props.setState} 
                  filterRecipesList={props.filterRecipesList}
                />
            </div>
        </div>
    );
}