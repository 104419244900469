import { Fragment, useState } from 'react';
import '../App.css';

export default function FormListItem (props) {

    const [formListItemState, setFormListItemState] = useState({ ...props.data });

    const handleFieldChange = (e) => {
        let newState = { ...formListItemState, [e.currentTarget.id]: e.currentTarget.value };
        setFormListItemState(newState);
        props.handleListItemChange({ index: props.index, dataType: props.dataType, formListItemData: newState });
    }

    const handleRemoveItem = (e) => {
        let arr = props.state.formData[e.currentTarget.value];
        arr.splice(props.index, 1);
        props.setState({ ...props.state, formData: { ...props.state.formData, [e.currentTarget.value]: arr } });
    }

    return (
        <div className='container row margin-xs align-top'>
            {props.dataType === 'ingredients' &&
                <Fragment>
                    <input className='padding-xs margin-xs width-xs' id='amount' placeholder='amount' type='number' value={formListItemState.amount} onChange={handleFieldChange} />
                    <input className='padding-xs margin-xs width-xs' id='measurement' placeholder='measurement' type='text' value={formListItemState.measurement} onChange={handleFieldChange} />
                    <input className='padding-xs margin-xs width-m' id='name' placeholder='name' value={formListItemState.name} onChange={handleFieldChange} />
                    <input className='padding-xs margin-xs width-xs' id='calories' placeholder='calories' type='number' value={formListItemState.calories} onChange={handleFieldChange} />
                    <button className='background-none border-none remove-button border-radius-s fill-on-hover bold font-size-m' value='ingredients' onClick={handleRemoveItem} >X</button>
                </Fragment>
            }
            {props.dataType === 'steps' &&
                <Fragment>
                    <p className='margin-h-s margin-v-s font-xxl bold'>{props.index + 1}</p>
                    <textarea rows={5} cols={40} className='padding-xs margin-xs full-width' id='description' placeholder='description' type='text' value={formListItemState.description} onChange={handleFieldChange} />
                    <button className='background-none border-none remove-button border-radius-s fill-on-hover bold font-size-m' onClick={handleRemoveItem} value='steps'>X</button>
                </Fragment>
            }
        </div>
    );
}