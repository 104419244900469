import { useState } from 'react';
import Main from './components/main';
import Login from './components/login';
import Message from './components/message';
import './App.css';

function App() {

  const [state, setState] = useState({ 
    isLoggedIn: false, 
    view: 'recipes',
    formView: 'create',
    formData: {
      name: '',
      caloriesPerServing: '',
      servings: '',
      tags: [],
      ingredients: [{
        amount: '',
        measurement: '',
        name: '',
        calories: ''
      }],
      steps: [
        {
          stepNumber: 1,
          heading: '',
          description: ''
        }
      ],
      cuisine: '',
      category: ''
    },
    hasMessage: false,
    message: '',
    messageType: ''
  });

  const setMessage = (message, messageType = 'info', duration = 2500, additionalUpdates = null) => {
    let newState = { ...state, message: message, messageType: messageType, hasMessage: true, ...additionalUpdates };
    setState(newState);
    setTimeout(() => {
      setState({ ...newState, message: '', messageType: '', hasMessage: false });
    }, duration);
  }

  return (
    <div className="App container column justify-center align-center full-width">
      {state.hasMessage &&
        <Message state={state} setState={setState} />
      }
      {state.isLoggedIn &&
        <Main 
          state={state} 
          setState={setState} 
          setMessage={setMessage}
        />
      }
      {!state.isLoggedIn && 
        <Login 
          state={state} 
          setState={setState}
          setMessage={setMessage}
        />
      }
    </div>
  );
}

export default App;
