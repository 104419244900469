import '../App.css';

export default function Header (props) {

    const onMenuItemClick = (e) => {
        props.setState({ ...props.state, view: e.currentTarget.value});
    }

    const navigateToMain = () => {
        onMenuItemClick({ currentTarget: { value: 'recipes' }});
    }

    return (
        <div className='container row width-xl align-center modal top-0 background-color-white padding-h-m padding-v-xs box-shadow-header justify-left'>
            <div className='container column justify-left align-left'>
                <h1 className='margin-none padding-none font-size-xxl bold margin-h-xs cursor-pointer' onClick={navigateToMain} >our recipes.</h1>
                <div className='container row justify-left align-center margin-v-xs'>
                    <button className='background-none border-none cursor-pointer light-on-hover text-align-left padding-none' onClick={onMenuItemClick} value='recipes' >recipes</button>
                    <button className='background-none border-none cursor-pointer light-on-hover text-align-left padding-none' onClick={onMenuItemClick} value='prep list' >prep list</button>
                    <button className='background-none border-none cursor-pointer light-on-hover text-align-left padding-none' onClick={onMenuItemClick} value='shopping list' >shopping list</button>
                </div>
            </div>

        </div>
    );
}