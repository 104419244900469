import '../App.css';
import { useState, useEffect, Fragment } from 'react';
import { getAllPrepItems } from '../services/api-services';
import ListItem from './list-item';

export default function PrepList (props) {

    const [prepItemList, setPrepItemList] = useState(props.state?.prepItems || []);

    // const getAllPrepItems = async () => {
    //     let response = await getAllPrepItems();
    //     if (response.success) {
    //         props.setState({ ...props.state, prepItems: response.data });
    //         setPrepItemList(response.data);

    //         return;
    //     }
    //     props.setMessage(response.message, 'error');
    // }

    // useEffect(() => {
    //     if (props.state.fetchPrepItems) {
    //         getAllPrepItems();
    //     }
    // }, [props.state]);

    return (
        <div className='container column width-xl margin-h-m'>
            {prepItemList.length > 0 &&
                prepItemList.map((prepItem, index) => (
                    <ListItem 
                        key={prepItem.id}
                        state={props.state} 
                        setState={props.setState} 
                        setMessage={props.setMessage}
                        data={prepItem}
                        dataType='prep item'
                        index={index}
                    />
                ))
            }
            {(!prepItemList.length) &&
                <Fragment>
                    no prep items found
                </Fragment>
            }
        </div>
    );
}